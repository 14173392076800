@import "../styles/variables.scss";

.header {
  height: var($logo-height);
  display: flex;
  justify-content: center; // Centrer le contenu horizontalement
  align-items: center;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 1000;

  .container {
    // Ajout du conteneur
    width: 100%; // Prendre toute la largeur disponible
    padding: 0 10px; // Padding de 10px de chaque côté
    display: flex; // Disposition flexible pour le contenu
    justify-content: space-between; // Espacement entre le logo et la navigation
    align-items: center; // Alignement vertical centré

    .socialLinks {
      display: flex;
      gap: 10px;
    }

    .logoContainer {
      width: $logo-width;
      height: $logo-height;
      box-sizing: border-box;

      .logo {
        fill: $primary-color;
        width: 100%;
        height: 100%;
      }
    }

    .nav {
      display: flex;
      gap:0;
      margin: 0;
      width: 100%;
      justify-content: flex-end;
      a {
        color: $primary-dark;
        font-family: $font-primary;
        font-size: $font-size-heading;
        text-decoration: none;
        transition: all 0.3s;
        padding: 5px 10px;
        &:hover {
          color: $primary-hover;
          padding: 3px 10px 7px;
        }

        &.activeLink {
          color: $white;
          background-color: $primary-color;
          padding: 3px 10px 7px;
        }
      }
    }

    .mobileMenuButton {
      display: none;
      background: none;
      border: none;
      cursor: pointer;
      padding: 10px;
      z-index: 1001;

      span {
        display: block;
        width: 25px;
        height: 3px;
        background-color: $primary-color;
        margin: 5px 0;
        transition: all 0.3s ease;
      }

      &.open {
        span {
          &:nth-child(1) {
            transform: rotate(-45deg) translate(-5px, 6px);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(45deg) translate(-5px, -6px);
          }
        }
      }
    }

    a.socialLink {
      font-size: 1rem; // Ajustez selon vos besoins
      transition: color 0.3s ease;
      background-color: $primary-color;
      height: $logo-height;
      width: $logo-height;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $button-border-radius;
      box-sizing: border-box;
      color: $white; // Utilisez la couleur appropriée de votre palette
      &:hover {
        color: $accent-color; // Utilisez une couleur d'accentuation de votre palette
      }
    }

    @media (max-width: $breakpoint-desktop) {
      .nav {
        display: none;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        padding: $spacing-medium;

        &.navOpen {
          display: flex;
          justify-content: center;
          align-items: center;
          animation: fadeIn 0.3s ease-in-out;
        }

        a {
          color: $primary-light;
          padding: $spacing-small 0;
          font-size: 24px;
          margin: 15px 0;

          &:hover {
            color: $primary-hover;
          }
        }
      }

      .mobileMenuButton {
        display: block;
      }
    }

    @media (min-width: $breakpoint-tablet) {
      .nav {
        a {
          font-size: $font-size-heading;
        }
      }
    }

    @media (min-width: $breakpoint-desktop) {
      .nav {
        a {
          font-size: calc(#{$font-size-heading} * 1.1);
        }
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
