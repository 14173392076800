// Couleurs principales
$primary-color: #212121;
$third-color:#030725;
$white: #fafafa;
$text-dark:#262728;

// Configuration responsive
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

// Palette générée automatiquement
$primary-light: lighten($primary-color, 15%); // Couleur primaire plus claire
$primary-dark: darken($primary-color, 15%); // Couleur primaire plus foncée
$primary-hover: lighten($primary-color, 10%); // Couleur pour l'effet hover

// Variantes de gris
$gray-lightest: #f8f9fa;
$gray-lighter: #e9ecef;
$gray-light: #dee2e6;
$gray: #ced4da;
$gray-dark: #6c757d;
$gray-darker: #343a40;
$gray-darkest: #212529;

// Couleurs de fond et texte
$background-color: $gray-lightest;
$background-dark: $gray-darkest;
$text-color: $gray-darker;
$text-inverse: #ffffff;

// Couleur secondaire et accent
$secondary-color: adjust-hue($primary-color, 180); // Couleur secondaire (teinte opposée)
$accent-color: adjust-hue($primary-color, 90); // Couleur d'accent
$accent-light: lighten($accent-color, 20%); // Accent clair
$accent-dark: darken($accent-color, 20%); // Accent foncé

// Couleurs pour les états de succès, d'erreur, d'info et d'avertissement
$success-color: #28a745;
$success-light: lighten($success-color, 15%);
$success-dark: darken($success-color, 15%);

$error-color: #dc3545;
$error-light: lighten($error-color, 15%);
$error-dark: darken($error-color, 15%);

$info-color: #17a2b8;
$info-light: lighten($info-color, 15%);
$info-dark: darken($info-color, 15%);

$warning-color: #ffc107;
$warning-light: lighten($warning-color, 15%);
$warning-dark: darken($warning-color, 15%);



// Dimensions des bordures et coins arrondis
$border-radius: 0px;
$button-border-radius: 100px; // Pour les boutons en particulier

// Variables de typographie
$font-primary: 'PhosphateSolid', sans-serif;
$font-size-heading: 25px;
$font-weight-heading: 400;
$line-height-heading: 35px; // Alignement avec la hauteur de certains boutons

// Bouton principal
$button-padding: 5px 20px;
$button-gap: 10px;
$button-width: 158px;
$button-height: 45px;
$button-border-width: 2px;
$button-border-color: $white;
$button-bg-color: transparent;
$button-text-color: $white;

// Autres tailles et espacements
$spacing-small: 5px;
$spacing-medium: 10px;
$spacing-large: 10px;

// Variables de flexibilité pour les mises en page
$flex-align-center: center;
$flex-justify-center: center;
$flex-justify-between: space-between;

// Variables pour le placement du logo et du header
$header-padding: 5px;
$logo-width: 50px;
$logo-height: 50px;

// Variable pour la hauteur du hero sur les pages
$hero-height: 50vh;





:root {
  --primary-color: #{$primary-color};
  --white: #{$white};

  --hero-height: min(100vh, 50vh);
  @media screen and (max-width: 768px) {
    --hero-height: min(100vh, 50vh);
  }

  @media screen and (max-width: 480px) {
    --hero-height: min(100vh, 55vh);
  }

  @media screen and (min-height: 1000px) {
    --hero-height: min(100vh, 50vh);
  }

  
}

@mixin responsive-padding($default, $tablet: null, $mobile: null) {
    padding: $default; // Valeur par défaut
  
    @media (max-width: 768px) {
      padding: if($tablet, $tablet, $default); // Si une valeur pour tablette est fournie
    }
  
    @media (max-width: 480px) {
      padding: if($mobile, $mobile, if($tablet, $tablet, $default)); // Si une valeur pour mobile est fournie
    }
  }
  