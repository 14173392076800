// src/styles/typography.scss

@import './variables.scss';

// Breakpoints pour la responsivité
$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1440px;

// Typographie - tailles et responsivité
$font-size-base: 16px;

$font-sizes: (
  xs: 14px,
  sm: 16px,
  base: 14px,
  lg: 20px,
  xl: 30px,
  xxl: 40px,
);

@mixin responsive-font($size) {
  font-size: map-get($font-sizes, $size);

  @media (min-width: $breakpoint-xs) {
    font-size: calc(map-get($font-sizes, $size) * 1.1);
  }

  @media (min-width: $breakpoint-md) {
    font-size: calc(map-get($font-sizes, $size) * 1.2);
  }

  @media (min-width: $breakpoint-lg) {
    font-size: calc(map-get($font-sizes, $size) * 1.3);
  }
}

// Utilisation des familles et poids de police
$font-primary: "DIN Next LT Pro Medium Cond", sans-serif;
$font-secondary: "Phosphate", sans-serif;

@mixin font-primary {
  font-family: $font-primary;
}

@mixin font-secondary {
  font-family: $font-secondary;
}

// Mixin pour les styles de texte communs
@mixin heading($size: base, $weight: bold, $transform: uppercase, $line-height: .8) {
  @include responsive-font($size);
  font-weight: $weight;
  text-transform: $transform;
  line-height: $line-height;
  color: inherit;
}

@mixin body-text($size: base) {
  @include responsive-font($size);
  font-weight: normal;
  line-height: 1.3;

}

// Exemples de classes pour la typographie
h1 {
  @include heading(xxl);
  margin: 0;
  margin-block: 0
}

h2 {
  @include heading(xl);
  margin: 0;
  margin-block: 0
}

h3 {
  @include heading(lg);
  margin: 0;
  margin-block: 0
}

p, .body-text {
  margin: 0;
  margin-block: 0
}

.small-text {
  @include body-text(sm);
  margin: 0;
  margin-block: 0
}

.caption-text {
  @include body-text(xs);
  margin: 0;
  margin-block: 0
}

// Container responsif pour l'app
.container {
  max-width: 100%;
  padding: 0 20px;

  @media (min-width: $breakpoint-xs) {
    max-width: 540px;
  }

  @media (min-width: $breakpoint-sm) {
    max-width: 720px;
  }

  @media (min-width: $breakpoint-md) {
    max-width: 960px;
  }

  @media (min-width: $breakpoint-lg) {
    max-width: 1140px;
  }

  @media (min-width: $breakpoint-xl) {
    max-width: 1320px;
  }
}
