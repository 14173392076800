.imageWithOverlay {
    position: relative;
    overflow: hidden;
  }
  
  .overlayImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
    max-height: 50%;
    z-index: 1;
  }