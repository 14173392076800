@import "../styles/variables.scss";

.footer {
    background-color: $primary-color; // Couleur de fond du footer
    color: #ffffff; // Couleur du texte
    padding: 20px; // Espacement interne
    text-align: center; // Centrer le contenu

    .container { // Ajout du conteneur
      max-width: 1440px; // Largeur maximale
      margin: 0 auto; // Centrer le contenu
      padding: 0 10px; // Padding de 10px de chaque côté

      .footerContent {
        max-width: 1200px; // Largeur maximale du contenu
        margin: 0 auto; // Centrer le contenu
        
        p {
          margin: 0; // Retirer la marge par défaut
        }

        nav {
          margin-top: 10px; // Espacement au-dessus de la navigation

          .footerLinks {
            list-style: none; // Retirer les puces
            padding: 0; // Retirer le padding

            a {
                color: $white; // Couleur des liens
                text-decoration: none; // Retirer le soulignement

                &:hover {
                  text-decoration: underline; // Souligner au survol
                }
              }
          }
        }
      }
    }
}
