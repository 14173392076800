// src/components/Loader.module.scss
@import '../styles/variables.scss';

// components/Loader/Loader.module.scss
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  position: absolute;
  z-index: 99999999999;
  height: 100vh;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 3px solid var(--primary-color);
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}