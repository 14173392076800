@import "./styles/variables.scss"; // Import des variables globales
@import "./styles/typography.scss"; // Import de la typographie et des styles responsifs


::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent;
}

/* Styles globaux */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: $white;
  color: $white;
  overflow-y: hidden;
  height: 100dvh;
  box-sizing: border-box;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  scrollbar-color: gray transparent;

}

html {
  height: 100%;
  overflow-y: scroll;
  background-color: $white;
}
body::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea {
  font-size: 16px;
}

/* Conteneur principal de l'application */
.app-container {
  margin: 0 auto; // Centrer le conteneur
  min-height: 100vh;
  display: flex;
  flex-direction: column; // Pour empiler les sections verticalement
  gap: 20px; // Définit un espace de 20px entre chaque section

  padding-top: $logo-height;
}
.swiper-button-prev,
.swiper-button-next {
  color: $accent-dark;
}

section {
  margin: 0;
  background-color: $white;
}
