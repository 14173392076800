// src/styles/fonts.scss

@font-face {
    font-family: 'PhosphateSolid';
    src: url('../assets/fonts/PhosphatePro-Solid.woff2') format('woff2'),
         url('../assets/fonts/PhosphatePro-Solid.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap; // Améliore le rendu en attendant le chargement
  }
  @font-face {
    font-family: 'DIN Next LT Pro Medium Cond';
    src: url('../assets/fonts/DINNextLTPro-MediumCond.woff2') format('woff2'),
        url('../assets/fonts/DINNextLTPro-MediumCond.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


  // Exemple d'utilisation globale
  h1, h2, h3 {
    font-family: 'PhosphateSolid', sans-serif;
    font-weight: normal;
  }
  