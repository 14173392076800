@import "../styles/variables.scss";

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $button-padding; // Variable pour la marge interne
    gap: $button-gap; // Variable pour l'écart entre les éléments internes
    height: -webkit-fit-content;
    border: $button-border-width solid $button-border-color; // Variables pour la bordure
    border-radius: $button-border-radius; // Variable pour le radius global des boutons
    background-color: $white; // Couleur d'arrière-plan par défaut (version light)
    color: $primary-color; // Couleur du texte par défaut
    font-family: $font-primary; // Police de caractère
    font-size: $font-size-heading; // Taille de police pour le texte des boutons
    line-height: $line-height-heading; // Hauteur de ligne
    margin: auto 4px;
    cursor: pointer;
    transition:
        background-color 0.3s ease,
        transform 0.2s ease; // Ajouter la transition pour transform
    text-decoration: none;

    // Effet de mise à l'échelle au survol
    &:hover {
        background-color: $primary-hover; // Couleur primaire au survol
        color: $white; // Couleur du texte au survol
    }

    // Réduire la taille au clic
    &:active {
    }
}

// Version sombre du bouton
.buttonDark {
    background-color: $white; // Couleur d'arrière-plan sombre
    color: $primary-color; // Couleur du texte clair
    border: 2px solid $primary-color; // Bordure en couleur primaire claire

    // Effets de survol et d'activation
    &:hover {
        background-color: $primary-color; // Couleur d'arrière-plan au survol
        color: $white; // Couleur du texte au survol
    }

    &:active {
        background-color: $primary-color; // Couleur d'arrière-plan au clic
        color: $white; // Couleur du texte au clic
    }
}

// Version claire du bouton
.buttonLight {
    background-color: $white; // Couleur d'arrière-plan claire
    color: $primary-color; // Couleur du texte
    border: 2px solid $white; // Bordure en couleur primaire

    // Effets de survol et d'activation
    &:hover {
        background-color: $primary-hover; // Couleur primaire au survol
        color: $white; // Couleur du texte au survol
    }

    &:active {
        background-color: $primary-color; // Couleur d'arrière-plan au clic
        color: $white; // Couleur du texte au clic
    }
}

// Styles pour les petits boutons
.smallButton {
    padding: $button-padding / 2; // Réduire le padding pour un petit bouton
    font-size: $font-size-heading / 1.4; // Réduire la taille de la police
    line-height: 1em; // Garder la hauteur de ligne

    // Garder les effets hover et active similaires
    &:hover {
        background-color: $primary-hover; // Couleur primaire au survol
        color: $white; // Couleur du texte au survol
    }

    &:active {
        background-color: $primary-color; // Couleur d'arrière-plan au clic
        color: $white; // Couleur du texte au clic
    }
}

// Styles pour l'état actif
.active {
    background-color: $primary-color; // Couleur d'arrière-plan pour l'état actif
    color: $white; // Couleur du texte pour l'état actif
    border: 2px solid $primary-light; // Bordure pour l'état actif

    // Effets de survol et d'activation
    &:hover {
        background-color: $primary-color; // Couleur d'arrière-plan au survol en état actif
        color: $white; // Couleur du texte au survol en état actif
    }

    &:active {
        background-color: $primary-color; // Couleur d'arrière-plan au clic en état actif
        color: $white; // Couleur du texte au clic en état actif
    }
}
